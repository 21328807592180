import abstractService from '../abstractService';

const getProductsPublicUrl = ({ merchantId, page, elements }) =>
  `products?fromMerchant=${merchantId}&page=${page}&elements=${elements}&withoutMerchantInfo=true&publish=true&sortByOrderAsc=true`;
const getProductByIdUrl = ({ productId }) => `products/${productId}`;

export const getProducts = ({ filter, paginationByPageNumbers }) => {
  return abstractService.getRequest(getProductsPublicUrl, null, {
    filter,
    paginationByPageNumbers,
  });
};

export const getProductById = ({ productId }) => {
  const bodyData = {
    productId,
  };
  return abstractService.getRequest(getProductByIdUrl, null, bodyData);
};
