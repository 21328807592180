import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { selectCurrentPage } from './productsSelector';
import { selectMerchantId } from '../merchant/merchantSelector';
import { getProducts } from '../../services/productsService';
import { selectCategory } from '../categories/categoriesSelector';

const PRODUCTS_PER_PAGE = 9;

const initialState = {
  page: 1,
  values: [],
  pendingGetProducts: true,
  errorGettingProducts: null,
  showLoadMore: false
};

const areaDataCache = {};

export const getProductsThunk = createAsyncThunk(
  'productsSlice/GET_PRODUCTS',
  async (merchant_id, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState();
      const category = selectCategory(state);
      
      const page = selectCurrentPage(state);

      const postcode = `products/${category ? category : "NULL"}/${page}`;

      if (areaDataCache[postcode]) {
        dispatch(addPage());
        return areaDataCache[postcode];
      }

      let merchantId = merchant_id;
      if (!merchantId) {
        merchantId = selectMerchantId(state);
      }
      if (!merchantId) {
        return rejectWithValue({ message: 'There is no merchant id' });
      }

      const paginationByPageNumbers = {
        page: page,
        elements: PRODUCTS_PER_PAGE,
      };
      const filter = {
        // publish: true,
        fromMerchant: merchantId,
        // fromMerchant: '5d703a9bf7063d00116a093e',
        // withActiveExtractions: true,
        sortByOrderAsc: true,
      };

      if(category !== null) {
        filter.categories = [category];
      }

      const response = await getProducts({ filter, paginationByPageNumbers });
      if (response.status === 200) {
        if (response.data && response.data.length > 0) {
          dispatch(addPage());
        }
        areaDataCache[postcode] = response.data;
        return response.data;
      }
      return rejectWithValue({ response });
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log('Error getProductsThunk', {
          response: error.response,
          config: error.config,
        });
        return rejectWithValue({
          response: error.response,
          config: error.config,
        });
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        console.log('Error getProductsThunk', {
          request: error.request,
          config: error.config,
        });
        return rejectWithValue({
          request: error.request,
          config: error.config,
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error getProductsThunk', {
          message: error.message,
          config: error.config,
        });
        return rejectWithValue({
          message: error.message,
          config: error.config,
        });
      }
    }
  },
);

export const ProductsSlice = createSlice({
  name: 'Products',
  initialState,
  reducers: {
    addPage: (state) => {
      state.page = state.page + 1;
    },
    firstPage: (state) => {
      state.page = 1;
      state.values = [];
    }
  },
  extraReducers: {
    [getProductsThunk.pending]: (state) => {
      state.pendingGetProducts = true;
      state.errorGettingProducts = null;
      state.showLoadMore = false;
    },
    [getProductsThunk.rejected]: (state, action) => {
      state.pendingGetProducts = false;
      state.errorGettingProducts = action.payload;
      state.showLoadMore = false;
    },
    [getProductsThunk.fulfilled]: (state, action) => {
      state.values.push(...action.payload);
      state.pendingGetProducts = false;
      state.errorGettingProducts = null;
      state.showLoadMore = action.payload.length === PRODUCTS_PER_PAGE;
    },
  },
});

export const { addPage, firstPage } = ProductsSlice.actions;

export default ProductsSlice.reducer;
