import './App.css';
import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import AppRoutes from './routes/routes';
import Theme from './utils/theme';
import { getMerchantByDomainNameThunk } from './redux/merchant/merchantSlice';

const theme = createTheme(Theme);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    let domain = window.location.hostname;
    console.log('domain', domain);
    if (
      domain.includes('localhost') ||
      domain.includes('192.168') ||
      domain.includes('dev.web-app-merchant.goyuge.com') ||
      domain.includes('qa.web-app-merchant.goyuge.com') ||
      domain.includes('homo.web-app-merchant.goyuge.com')
    ) {
      //this condition is only for testing purposes
      domain = 'www.osielprod.nl/';
    }
    dispatch(getMerchantByDomainNameThunk(domain));
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <AppRoutes />
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
