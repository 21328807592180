import React, {useEffect, useRef, useState} from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'wouter';
import { goToAR } from '../utils/augmentedReality';

import styles from './ProductItem.module.css';
import ARBadgeIcon from '../assets/images/icons/AR_badge.svg';
import Carousel from 'react-material-ui-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { setVariant } from '../redux/variants/variantsSlice';
import { selectCurrentVariant } from '../redux/variants/variantsSelector';
import { useSwipeable } from 'react-swipeable';
import ModelViewer from './ModelViewer';

import next from '../assets/images/icons/aright.svg';
import prev from '../assets/images/icons/aleft.svg';

import init from '../utils/easel';
import {getImageUri} from "../utils/images";

const ProductItem = ({ product, onChange, isExtraction }) => {
  const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const [_, setWouterLocation] = useLocation();
  
  const [variants, setVariants] = useState([]);
  const [variantIndex, setVariantIndex] = useState(0);
  const [extractions, setExtractions] = useState([]);
  const [extractionIndex, setExtractionIndex] = useState(0);

  const swipeConfig = useSwipeable({
    onSwiped: (eventData) => swipeVariant(eventData),
    delta: { up: 1000000, down: 1000000, left: 100, rigth: 100 },
    preventScrollOnSwipe: false,
    trackTouch: true,
    trackMouse: false,
    rotationAngle: 0,
    swipeDuration: 500,
    touchEventOptions: { passive: true },
  });

  const selected = useSelector(selectCurrentVariant);

  const getFilename = () => {
    const activeExtraction = product?.extractions?.filter((i) => i.active === true)[0];
    const res = activeExtraction ? activeExtraction : product?.extractions[0];
    return res.filename;
  };

  const getCorrectGlb = (exFilename) => {
    return product?.glbFiles?.filter((i) => i.ext_filename === exFilename);
  };

  const getCorrectUsdz = (exFilename) => {
    return product?.usdzFiles?.filter((i) => i.ext_filename === exFilename);
  };

  const isMobile = () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      return true;
    } else {
      return window.innerWidth <= 768;
    }
  }

  const handleGoToARCallback = (url) => {
    if(isMobile()) {
      return window.open(url, 'blank');
    } else {
      return setWouterLocation(url);
    }
  }

  //TODO: In this function, add support for uploaded 3d ar assets and active
  const handleOnClickProduct = async (index) => {
    const filename = getFilename();
    let newProduct = {
      ...product,
      glbFiles: getCorrectGlb(filename),
      usdzFiles: getCorrectUsdz(filename),
    };
    if (index > 0) {
      newProduct = {
        ...newProduct,
        glbFiles: [variants[index].glbFile],
        usdzFiles: [variants[index].usdzFile],
      };
      await dispatch(
        setVariant({
          variantIndex: index,
          productId: product.id,
        }),
      );
      goToAR({product: newProduct, ARNotSupportedFallback: () => handleGoToARCallback(`/product/${product.id}/${index}`)});
    } else {
      goToAR({product:newProduct, ARNotSupportedFallback: () => handleGoToARCallback(`/product/${product.id}`)});
    }
  };

  const handleOnChange = async (e) => {
    try {
      onChange(e);
      if(isExtraction){
        await setExtractionIndex(e);
      } else {
        await setVariantIndex(e);
      }
    } catch (err) {}
  };

  function swipeVariant(eventData) {
    const { dir } = eventData;
    let dirValue = 0;
    if (dir === 'Left') dirValue = 1;
    if (dir === 'Right') dirValue = -1;

    if (dirValue === 0) return;
    
    if(isExtraction){
      const value = (extractionIndex + dirValue + extractions.length) % extractions.length;
      setExtractionIndex(value);
    } else {
      const value = (variantIndex + dirValue + variants.length) % variants.length;
      setVariantIndex(value);
    }
  }

  const initAnimation = (error) => {
    const img = imageRef.current;
    const canvas = canvasRef.current;

    if (!error && img?.complete && canvas) {
      init(canvas, img);
    } else {
      console.log('Image extraction load failure!');
    }
  };
  
  useEffect(() => {
    if(isExtraction){
      const activeExtraction = product?.extractions?.filter((i) => i.active === true);
      //TODO: This is mapping only one extraction for now, we need to check if needs to map more than one
      const ext = activeExtraction?.length ? activeExtraction: [product?.extractions[0]];
      setExtractions([ext]);
    } else {
      //Active or first extraction + all posible variants
      const activeExtraction = product.extractions.filter((i) => i.active === true);
      const resources = [
        {
          image: {
            URL: getImageUri(product.images, false, true),
          },
          extraction: {
            URL: activeExtraction ? activeExtraction.URL : product?.extractions[0].URL,
          },
        },
      ]
      
      if (product.variants) {
        resources.push(...product.variants);
      }
      setVariants(resources);
    }
  }, [isExtraction, product]);
  
  useEffect(() => {
    try {
      if (selected?.productId === product.id) {
        if(isExtraction){
          setExtractionIndex(selected.variantIndex);
        }else {
          setVariantIndex(selected.variantIndex);
        }
        onChange(selected.variantIndex);
      }
    } catch (e) {}
  }, [isExtraction, onChange, product.id, selected]);
  
  console.log('DATOS A REPRESENTAR', isExtraction, variants, extractions)

  const ExtractionCarousel = () => {
    const extractionCarouselVariables = {
      extractionIndex:extractionIndex,
      extractions:extractions,
      imageRef:imageRef,
      canvasRef:canvasRef
    };
    console.log('extractionCarouselVariables',extractionCarouselVariables)
    return (
    <Carousel
      index={extractionIndex}
      timeout={0}
      swipe={true}
      onChange={handleOnChange}
      autoPlay={false}
      NextIcon={<img alt="next" src={next} />}
      PrevIcon={<img alt="previous" src={prev} />}
      sx={{ height: '100% !important' }}
      navButtonsProps={{
        className: styles.navButtonsDetails,
        style: {
          opacity: extractions.length > 1 ? 1 : 0,
        },
      }}
      indicatorContainerProps={{
        className: styles.indicatorContainer,
        style: {
          display: extractions.length > 1 ? 'block' : 'none',
        },
      }}
      indicatorIconButtonProps={{
        className: styles.exindicatorIconDetails,
      }}
      activeIndicatorIconButtonProps={{
        className: styles.exactiveIndicatorIconDetails,
      }}>
      {extractions?.map((extraction, key) => {
        console.log('extractionextraction',extraction)
        // console.log('extractionextractionURL',extraction[key].URL)
        
        return (
        <div key={key} className={styles.variantContainer}>
          {key === extractionIndex && <img
            id="currentExtraction"
            ref={imageRef}
            crossOrigin="anonymous"
            onLoad={() => initAnimation(false)}
            onError={() => initAnimation(true)}
            style={{ display: "none" }}
            className={styles.variantExt}
            onClick={() => handleOnClickProduct(key)}
            src={extraction && extraction[key]?.URL ? extraction[key].URL: ""}
            alt="AR Product Extraction"
          />}
          {key === extractionIndex && <canvas id="canvas" ref={canvasRef} width="750" height="750" style={{ width: "100%" }} />}
        </div>
      )}
      )}
    </Carousel>
  )};

  const VariationsCarousel = () => (
    <Carousel
      index={variantIndex}
      timeout={0}
      swipe={false}
      onChange={handleOnChange}
      autoPlay={false}
      sx={{ height: '100% !important', borderRadius: '10px', border: '2px solid #e0e0e0' }}
      navButtonsProps={{
        className: styles.navButtons,
        style: {
          opacity: variants.length > 1 ? 1 : 0,
        },
      }}
      indicatorContainerProps={{
        className: styles.indicatorContainer,
        style: {
          display: variants.length > 1 ? 'block' : 'none',
        },
      }}
      indicatorIconButtonProps={{
        className: styles.indicatorIcon,
      }}
      activeIndicatorIconButtonProps={{
        className: styles.activeIndicatorIcon,
      }}>
      {variants?.map((variant, key) => {
        console.log('variantvariant',variant)
        return(
        <div key={key} className={styles.variantContainer}>
          <img alt="" className={styles.variant} onClick={() => handleOnClickProduct(key)} src={variant.image.URL} />
          <div className={styles.aRIconContainer}>
            <img src={ARBadgeIcon} alt={'AR logo'} className={styles.aRIcon} />
          </div>
          {product.sold && <div className={styles.soldOut}>Uitverkocht</div>}
        </div>
      )}
      )}
    </Carousel>
  );

  return (
    <Box className={styles.boxItem} {...swipeConfig}>
      {isExtraction ? ExtractionCarousel() : VariationsCarousel()}
      {/* {isExtraction ? <ModelViewer url="/10.glb"/> : VariationsCarousel()} */}
    </Box>
  );
};

export default ProductItem;
