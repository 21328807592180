import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';

import styles from './Store.module.css';
import CustomAppBar from '../../components/AppBar';
import StoreHeader from '../../components/StoreHeader';
import ProductContainer from '../../components/ProductContainer';
import { selectIsFetchingProducts, selectProducts, selectShowLoadMore } from '../../redux/products/productsSelector';
import { getProductsThunk } from '../../redux/products/productsSlice';
import { selectMerchantSettings } from '../../redux/merchant/merchantSelector';
import { BRANDING_TYPE } from '../../globals';
import CategoryTabs from '../tabs/CategoryTabs';
import { Grid } from '@mui/material';

const Store = () => {
  console.log('Store page component triggered')
  const dispatch = useDispatch();
  const showLoadMore = useSelector(selectShowLoadMore);
  const isFetching = useSelector(selectIsFetchingProducts);
  const products = useSelector(selectProducts);
  const settings = useSelector(selectMerchantSettings);

  const loadMoreHandler = () => {
    dispatch(getProductsThunk());
  };

  return (
    <>
      <CssBaseline />
      <CustomAppBar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}>
        <div className={styles.centered}>
          <Grid
            maxWidth="1536px"
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
            style={{ marginLeft: 8 }}>
            <CategoryTabs />
          </Grid>
        </div>
        <main className={styles.main}>
          {settings && settings.web_type === BRANDING_TYPE.full && (
            <Box sx={{ height: '42vh' }}>
              <StoreHeader />
            </Box>
          )}
          <div className={products.length === 0 && isFetching ? '' : styles.centered}>
            <ProductContainer products={products} />
          </div>
          {showLoadMore && (
            <Button variant="contained" className={styles.loadMoreButton} color={'secondary'} onClick={loadMoreHandler}>
              Load More
            </Button>
          )}
        </main>
        <footer className={styles.centered + " " + styles.footer}>
          Augmented Reality powered by <a href="https://goyuge.com/">YUGE</a>
        </footer>
      </Box>
    </>
  );
};

export default Store;
