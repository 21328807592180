import React, {useCallback, useLayoutEffect, useState} from 'react';
import { useLocation, useRoute } from 'wouter';
import { getProductById } from '../../services/productsService';
import { routes } from '../../routes/routes';
import { goToAR } from '../../utils/augmentedReality';
import { useDispatch } from 'react-redux';
import { setVariant } from '../../redux/variants/variantsSlice';
import { Alert } from '@mui/material';
import styles from '../productDetails/ProductDetails.module.css';

const ProductVariantAR = () => {
  console.log('Product variant page component triggered')
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [match, params] = useRoute(routes.productVariantAR);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [showNotFound, setShowNotFound] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const [variant, setVariantL] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [location, setLocation] = useLocation();

  const handleRedirect = useCallback(async (i, id) => {
    await dispatch(
      setVariant({
        variantIndex: i,
        productId: id,
      }),
    );
    setLocation('/product/' + id);
  }, [dispatch, setLocation]);

  const getFilename = (p) => {
    const activeExtraction = p.extractions.filter((i) => i.active === true)[0];
    return activeExtraction.filename;
  };

  const getCorrectGlb = (exFilename, p) => {
    return p.glbFiles.filter((i) => i.ext_filename === exFilename);
  };

  const getCorrectUsdz = (exFilename, p) => {
    return p.usdzFiles.filter((i) => i.ext_filename === exFilename);
  };

  const getProduct = useCallback(async (productId) => {
    try {
      const res = await getProductById({ productId });
      if (res?.data) {
        setProduct(res.data);

        const urlSearchParams = new URLSearchParams(window.location.search);
        const queryParams = Object.fromEntries(urlSearchParams.entries());
        if (!queryParams?.noAR) {
          const filename = getFilename(res.data);
          let newProduct = {
            ...res.data,
            glbFiles: getCorrectGlb(filename, res.data),
            usdzFiles: getCorrectUsdz(filename, res.data),
          };

          if (variant > 0) {
            const index = variant - 1;
            if (index >= 0)
              newProduct = {
                ...newProduct,
                glbFiles: [newProduct.variants[index].glbFile],
                usdzFiles: [newProduct.variants[index].usdzFile],
              };
          }
          if (variant != null) goToAR({product: newProduct, ARNotSupportedFallback: () => handleRedirect(variant, res.data.id)});
        } else setShouldRender(true);
      }
      setLoading(false);
    } catch (error) {
      setShowNotFound(true);
      setShouldRender(true);
      setLoading(false);
      console.log('Error getting product by id', error);
    }
  }, [handleRedirect, variant]);

  useLayoutEffect(() => {
    if (params?.variant && variant === null) {
      setVariantL(parseInt(params.variant));
    }
    if (params?.id && !product) {
      getProduct(params.id).then();
    }
  }, [getProduct, params, product, variant]);

  if (loading) {
    return null;
  }

  if (!shouldRender) {
    return null;
  }

  if (showNotFound) {
    return (
      <div className={styles.centerMessage}>
        <Alert color="error">Product not found.</Alert>
      </div>
    );
  }

  return <></>;
};

export default ProductVariantAR;
